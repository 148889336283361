import React from 'react';
import ReactTooltip from 'react-tooltip';
import * as styles from './index.styles';
import TooltipQuestionImage from '~/assets/icon/tooltip-question--gray2.svg';
import { Global, SerializedStyles } from '@emotion/react';

export type Props = {
  tooltipId: string;
  children: React.ReactNode;
  option?: {
    place?: 'top' | 'bottom' | 'left' | 'right';
  };
  customCss?: SerializedStyles;
  testId?: string;
};

export const Tooltip: React.FC<Props> = (props: Props) => (
  <>
    <span css={props.customCss} data-testid={props.testId}>
      <img
        src={TooltipQuestionImage}
        alt=""
        data-tip
        data-for={props.tooltipId}
        css={styles.icon}
      />
    </span>
    <ReactTooltip
      // https://github.com/wwayne/react-tooltip/issues/587#issuecomment-619675399
      uuid={props.tooltipId}
      id={props.tooltipId}
      effect="solid"
      className={styles.tooltipClassName}
      place={props.option?.place}
    >
      {props.children}
    </ReactTooltip>
    <Global styles={styles.customTooltip} />
  </>
);
