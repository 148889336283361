import React from 'react';
import * as styles from './index.styles';
import Alert from '~/assets/icon/alert.svg';
import { FontSizeName, FontWeightName } from '~/styles/font';
import { textCss } from '~/styles/textCss';
import { componentTokens } from '~/styles/designToken';
import { alignCss } from '~/styles/alignCss';

type Props = {
  errorMessage: string | string[];
  size?: FontSizeName;
  weight?: FontWeightName;
  pt?: number;
  pb?: number;
};

// NOTE: propsのrestはdata-testidなどを想定しているが、それ以外も渡されうるので改善の余地がありそう
export const ErrorMessage = ({
  errorMessage,
  size = 'xs',
  weight = 'bold',
  pt = 0.25,
  pb = 0,
  ...rest
}: Props): JSX.Element => {
  const message = Array.isArray(errorMessage) ? errorMessage.join('\n') : errorMessage;
  const textStyle = [
    styles.errorMessage.text,
    textCss({ size, weight, color: componentTokens.text.errorMessage }),
  ];
  return (
    <div {...rest} css={[styles.errorMessage.container, alignCss({ pt, pb })]}>
      <img css={styles.errorMessage.icon} src={Alert} alt="" aria-hidden />
      <span css={textStyle} role="alert">
        {message}
      </span>
    </div>
  );
};
