import { css } from '@emotion/react';
import { textStyle } from '~/styles/textStyle';

export const tooltipClassName = 'custom-tooltip';

export const icon = css`
  cursor: pointer;
  display: inline;
  vertical-align: middle;
  width: 24px;
  height: 24px;
`;

export const customTooltip = css`
  .${tooltipClassName} {
    ${textStyle('xs', 'regular')}

    line-height: 20px;
  }
`;
